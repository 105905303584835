import React from "react";
import { Modal } from "react-bootstrap";
import "./style.css";

const Popup = ({
  isOpen,
  setShowModal,
  children,
}: {
  setShowModal: (value: boolean) => void;
  children: JSX.Element | JSX.Element[];
  isOpen: boolean;
}) => {
  return (
    <Modal show={isOpen} onHide={() => {}} centered>
      <div className="modalWrapper">
        <div className="textRight minHeight">
          {" "}
          <a className="closePopup" onClick={() => setShowModal(false)}>
            <img src="assets/images/svg/close.svg" alt="close icon" />
          </a>
        </div>
        <Modal.Body style={{ backgroundColor: "white" }}>{children}</Modal.Body>
        <div style={{ padding: "20px" }}>
          <button
            type="button"
            className="btn-success form__btn"
            onClick={() => setShowModal(false)}>
            Ok
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default Popup;
