import { FunctionComponent } from "react";

interface HeaderWinnerProps {}

const HeaderWinner: FunctionComponent<HeaderWinnerProps> = () => {
  return (
    <header className="header">
      <div className="shell">
        <a  className="logo">
          <img src="./assets/images/svg/logo.svg" alt="logo" />
        </a>
      </div>
      {/* /.shell */}
    </header>
  );
};

export default HeaderWinner;
