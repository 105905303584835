import { Route } from "react-router-dom";
import Closed from "../Pages/Closed";
import ComingSoon from "../Pages/ComingSoon";
import Open from "../Pages/Open";
import Landing from "../Pages/Open/Landing";
import "../i18n";
import Congratulate from "../Pages/Open/Congratulate";
import Error from "../Pages/Error";
import ContestRules from "../Pages/Open/ContestRules";

export default (
  <>
    <Route path="/" element={<Open />}>
      <Route path=":sessionKey/:prizeWinnerKey" element={<Landing />} />
      <Route path="congratulate" element={<Congratulate />} />
      <Route path="error" element={<Error />} />
      <Route path="rules" element={<ContestRules />} />
    </Route>

    <Route path="closed" element={<Closed />} />
    <Route path="coming" element={<ComingSoon />} />
  </>
);
