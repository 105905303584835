
import { useRequiredCampaignState } from "../../helpers/campaignStates";
import HeaderWinner from "../../Components/Header";
import FooterWinner from "../../Components/FooterWinner";

export default function ComingSoon() {
  useRequiredCampaignState("comingsoon");

  return (
    <div className="wrapper wrapper--sticky-footer">
      <HeaderWinner />
      <div className="main main--sticky-footer">
        <section className="section-prize">
          <div className="shell">
            <div className="section__inner">
              <h1>Coming soon.</h1>
            </div>
            {/* /.section__inner */}
          </div>
          {/* /.shell */}
        </section>
        {/* /.section-prize */}
      </div>
      {/* /.main */}

      <FooterWinner />
    </div>
  );
}
