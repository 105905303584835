function Rules() {
  return (
    <>
      <div className="textCenter">
        <p>
          <strong>
            Walmart Canada Holiday Contest 2023
            <br /> (the “Contest”)
          </strong>
        </p>
        <p>
          <strong>Official Rules</strong>
        </p>
      </div>

      <p>&nbsp;</p>
      <table width="104%">
        <tbody>
          <tr>
            <td>
              <p>&nbsp;</p>
            </td>
            <td width="99%">
              <p>
                <strong>
                  NO PURCHASE NECESSARY TO ENTER OR WIN. A PURCHASE DOES NOT IMPROVE YOUR CHANCES OF
                  WINNING.
                </strong>
              </p>
            </td>
          </tr>
          <tr>
            <td>&nbsp;</td>
            <td width="99%">&nbsp;</td>
          </tr>
          <tr>
            <td>
              <p>&nbsp;</p>
            </td>
            <td width="99%">
              <p>
                <strong>
                  Contest may only be entered from within Canada and entries originating from any
                  other jurisdiction are not eligible for entry. You are not authorized to
                  participate in the Contest if you are not located in Canada. See additional
                  eligibility requirements in Rule 3 below.
                </strong>
              </p>
              <p>
                The Contest is brought to you by Wal-Mart Canada Corp.&nbsp; (“
                <strong>Walmart</strong>” or “<strong>Sponsor</strong>”) with its head office
                located at 1940 Argentia road, Mississauga, ON L5N 1P9, Canada. Contest is void in
                whole or part where prohibited by law. See Eligibility section below for further
                details. Participation in the Contest constitutes full and unconditional agreement
                and acceptance of these Official Contest Rules (the “Official Rules”) (which may be
                amended by Sponsor from time to time in its sole discretion). All dollar amounts are
                in Canadian funds ($CDN) unless otherwise noted.
              </p>
              <p>
                <strong>
                  The Contest is in no way sponsored, endorsed or administered by, or associated
                  with, Instagram.
                </strong>
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p>&nbsp;</p>
            </td>
            <td width="99%">
              <p>&nbsp;</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>&nbsp;</p>
            </td>
            <td width="99%">
              <p>
                <strong>1. How to Enter.&nbsp;</strong>
              </p>
            </td>
          </tr>
          <tr>
            <td>&nbsp;</td>
            <td width="99%">&nbsp;</td>
          </tr>
          <tr>
            <td>
              <p>&nbsp;</p>
            </td>
            <td width="99%">
              <p>
                To enter the Contest, entrants must follow the Sponsor @Walmart on Instagram using
                their accounts until the end of the Contest Period. Entrants should then participate
                by posting comments and tagging a friend in the comments on the Sponsor’s posts
                created during the Contest Period. Each comment constitutes one (1) entry. There
                will be a total of six (6) unique posts (“Unique Post(s)”), with one published every
                Thursday throughout the Contest Period, for greater certainty, the Unique Posts will
                be published on the following date:
              </p>
              <p>Unique Post 1: November 9, 2023</p>
              <p>Unique Post 2: November 16, 2023</p>
              <p>Unique Post 3: November 23, 2023</p>
              <p>Unique Post 4: November 30, 2023</p>
              <p>Unique Post 5: December 7, 2023</p>
              <p>Unique Post 6: December 14, 2023</p>
              <p>
                Use or attempted use of multiple identities, and/or any automated system to enter or
                otherwise participate in this Contest is prohibited and is grounds for
                disqualification. Entrants must ensure that their comments adhere to the community
                guidelines of Instagram available at{" "}
                <a href="https://help.instagram.com/477434105621119">
                  https://help.instagram.com/477434105621119
                </a>
                .
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p>&nbsp;</p>
            </td>
            <td width="99%">
              <p>&nbsp;</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>&nbsp;</p>
            </td>
            <td width="99%">
              <p>
                <strong>2. Contest Period.</strong>
              </p>
            </td>
          </tr>
          <tr>
            <td>&nbsp;</td>
            <td width="99%">&nbsp;</td>
          </tr>
          <tr>
            <td>
              <p>&nbsp;</p>
            </td>
            <td width="99%">
              <p>
                The Contest will begin on Thursday, November 9, 2023, and will end on Thursday,
                December 21, 2023, Eastern Time ET (the “<strong>Contest Period</strong>”).
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p>&nbsp;</p>
            </td>
            <td width="99%">
              <p>&nbsp;</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>&nbsp;</p>
            </td>
            <td width="99%">
              <p>
                <strong>3. Eligibility.</strong>
              </p>
            </td>
          </tr>
          <tr>
            <td>&nbsp;</td>
            <td width="99%">&nbsp;</td>
          </tr>
          <tr>
            <td>
              <p>&nbsp;</p>
            </td>
            <td width="99%">
              <p>
                The Contest is open only to legal residents of Canada who have reached the age of
                majority in their province or territory of residence as of the date of entry. Void
                outside of Canada, and where prohibited, taxed or restricted by law. Employees,
                representatives or agents of the Sponsor or Contest Administrator and their
                subsidiaries and their respective advertising and promotion agencies, the Contest
                judges, and the immediate family members and/or persons domiciled with any of the
                foregoing are not eligible to participate. “Immediate family member” means husband,
                wife, child, mother, father, sister, sister in-law, brother or brother in-law.
                Contest may only be entered from within Canada, and entries originating from any
                other jurisdiction are not eligible for entry. All federal, provincial, and local
                laws and regulations apply.
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p>&nbsp;</p>
            </td>
            <td width="99%">
              <p>&nbsp;</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>&nbsp;</p>
            </td>
            <td width="99%">
              <p>
                <strong>4. Random Drawing/Odds.</strong>
              </p>
            </td>
          </tr>
          <tr>
            <td>&nbsp;</td>
            <td width="99%">&nbsp;</td>
          </tr>
          <tr>
            <td>
              <p>&nbsp;</p>
            </td>
            <td width="99%">
              <p>
                There will be six (6) random drawings in the Contest and each random drawing will be
                conducted from all eligible entries received that are eligible for that drawing. For
                each drawing, three (3) potential winners will be selected from all eligible
                entries. Odds of winning in any particular drawing depend on the number of eligible
                entries received for that drawing. Each entry is eligible for only one drawing and
                will not be entered into subsequent drawings (if any). The random drawings will be
                conducted by a representative of the Sponsor at 219 Dufferin St, Unit 306A, Toronto,
                ON, M6K 3J1 and on the drawing time as set out in the chart below.
              </p>
            </td>
          </tr>
        </tbody>
      </table>

      <table width={323}>
        <tbody>
          <tr>
            <td width={161}>
              <p>Unique Post</p>
            </td>
            <td width={161}>
              <p>Time of Random Drawing</p>
            </td>
          </tr>
          <tr>
            <td width={161}>
              <p>Unique Post 1</p>
            </td>
            <td width={161}>
              <p>10 a.m., November 16, 2023</p>
            </td>
          </tr>
          <tr>
            <td width={161}>
              <p>Unique Post 2</p>
            </td>
            <td width={161}>
              <p>10 a.m., November 23, 2023</p>
            </td>
          </tr>
          <tr>
            <td width={161}>
              <p>Unique Post 3</p>
            </td>
            <td width={161}>
              <p>10 a.m., November 30, 2023</p>
            </td>
          </tr>
          <tr>
            <td width={161}>
              <p>Unique Post 4</p>
            </td>
            <td width={161}>
              <p>10 a.m., December 7, 2023</p>
            </td>
          </tr>
          <tr>
            <td width={161}>
              <p>Unique Post 5</p>
            </td>
            <td width={161}>
              <p>10 a.m., December 14, 2023</p>
            </td>
          </tr>
          <tr>
            <td width={161}>
              <p>Unique Post 6</p>
            </td>
            <td width={161}>
              <p>10 a.m., December 21, 2023</p>
            </td>
          </tr>
        </tbody>
      </table>

      <table>
        <tbody>
          <tr>
            <td>
              <p>&nbsp;</p>
            </td>
            <td width="99%">
              <p>
                <strong>5. Prizes.</strong>
              </p>
            </td>
          </tr>
          <tr>
            <td>&nbsp;</td>
            <td width="99%">&nbsp;</td>
          </tr>
          <tr>
            <td>
              <p>&nbsp;</p>
            </td>
            <td width="99%">
              <p>
                There are three (3) prizes available to be won for each drawing during the Contest
                Period (see Rule 4 above). Each prize consists of one (1) digital Walmart Canada
                gift card in the amount of CDN$100. There are a total of three (3) prizes to be
                awarded per drawing. A total of eighteen (18) prizes to be awarded during the entire
                Contest having a total value of CDN$1,800. Prizes must be accepted as awarded and
                are non-transferable. No substitutions or cash redemptions will be permitted. In the
                case of unavailability of any prize, Sponsor reserves the right, in its sole
                discretion, to substitute a prize of equal or greater value. Gift cards are subject
                to the Walmart gift card terms and conditions. There is a limit of one prize per
                person.
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p>&nbsp;</p>
            </td>
            <td width="99%">
              <p>&nbsp;</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>&nbsp;</p>
            </td>
            <td width="99%">
              <p>
                <strong>6. Notification.</strong>
              </p>
            </td>
          </tr>
          <tr>
            <td>&nbsp;</td>
            <td width="99%">&nbsp;</td>
          </tr>
          <tr>
            <td>
              <p>&nbsp;</p>
            </td>
            <td width="99%">
              <p>
                Sponsor will make reasonable attempts to notify potential winners. Sponsor will do
                this by responding to the comments (“the “<strong>Notification</strong>”) posted by
                the potential winner made on the social media platform used for contest entry, after
                the drawing time. If selected, entrants must send a direct message to the Sponsor
                within 48 hours of receiving the Notification, allowing the Sponsor to provide
                further information on the next steps for claiming the prizes. If this direct
                message is not sent within 48 hours, the Sponsor, at its sole discretion, may
                conduct a new drawing following the same rules to select an alternate potential
                winner.
              </p>
              <p>
                As a condition of winning the prize, the selected entrant must first correctly
                answer a mathematical skill testing question without aid or assistance and otherwise
                be in compliance with these Official Rules. Selected entrant may also be required to
                complete, sign, and return a declaration of compliance with the Official Rules and a
                liability and publicity release, and any other applicable forms that may be required
                by the Sponsor, within twenty-one (21) days of the date on the accompanying
                notification. If a selected entrant cannot be contacted within the specified time,
                fails to correctly answer the skill-testing question, does not complete and return
                any required forms within the time specified, or otherwise fails to comply with
                these Official Rules, he/she will be disqualified and will forfeit the prize and
                another entrant may be selected in accordance with these Official Rules. Prizes will
                be delivered to confirmed winners.
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p>&nbsp;</p>
            </td>
            <td width="99%">
              <p>&nbsp;</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>&nbsp;</p>
            </td>
            <td width="99%">
              <p>
                <strong>7. Conditions.</strong>
              </p>
            </td>
          </tr>
          <tr>
            <td>&nbsp;</td>
            <td width="99%">&nbsp;</td>
          </tr>
          <tr>
            <td>
              <p>&nbsp;</p>
            </td>
            <td width="99%">
              <p>
                By entering the Contest, entrants fully and unconditionally agree to be bound by
                these Official Rules and the decisions of the Sponsor, which will be final and
                binding in all matters relating to the Contest. By participating, entrants agree to
                release and hold harmless Sponsor, Contest Administrator, Instagram, their
                &nbsp;advertising and promotion agencies and their respective parent companies,
                subsidiaries, affiliates, partners, representatives, agents, successors, assigns,
                employees, officers and directors, from any and all liability, including without
                limitation, for any loss, harm, damage, injury, cost or expense, personal injury
                and/or death which may occur in connection with entrants’ participation in the
                Contest, or possession, acceptance and/or use or misuse of any prize and for any
                claims based on publicity rights, defamation or invasion of privacy. Sponsor is not
                responsible if Contest cannot take place or if any prize cannot be awarded due to
                reasons outside of the Sponsor’s control including acts of war, natural disasters,
                weather or acts of terrorism. Entrants who do not comply with these Official Rules,
                or who attempt to interfere with this Contest in any way shall be disqualified.
              </p>
              <p>
                The contents of the entries must be original, have not been previously published or
                won any other prize/award, nor infringe copyright or other intellectual property
                rights of any other party, and must be suitable for publication (i.e., must not be
                obscene or indecent or contain content that is or could be considered inappropriate,
                unsuitable, or offensive, all as determined by the Sponsor in its sole discretion.)
                Sponsor reserves the right, in its sole discretion, to disqualify any potential
                winner the Sponsor deems not to be in keeping with the rules of this Contest, or
                that the Sponsor learns has been previously published, entered into another contest
                or competition, or won any other prize or award.
              </p>
              <p>
                Entries must not: include celebrities (or celebrity likeness); disparage any persons
                or organizations; contain any personal or commercial solicitations, advertisements
                or promotional materials; feature alcoholic beverages, tobacco products, firearms
                and ammunitions, prescription drugs, illegal drugs and related drug paraphernalia,
                nutritional supplements, lotteries, or gambling; contain material which is in
                Sponsor's sole discretion, hateful, slanderous, libelous, tortious, sexually
                explicit, violent, discriminatory (including based on race, sex, religion, natural
                origin, physical disability, sexual orientation or age), profane, or harassing; or
                contain material that is threatening to any person, place, business or group or
                contain words or symbols that are widely considered offensive to individuals of a
                certain race, ethnicity, religion, sexual orientation or socioeconomic group;
                violate any law, statute, ordinance or regulation and must be in line with the
                Sponsor's image and reputation as determined by Sponsor, in its sole discretion.
              </p>
              <p>
                All content (comments, photos, rich media, etc.) of the entries shall become the
                property of the Sponsor. The entrant irrevocably assigns and transfers to the
                Sponsor any and all rights, title and interest in the content (comments, photos,
                rich media, etc.) including, without limitation, all copyright. Sponsor shall have
                the right to edit or modify content (comments, photos, rich media, etc.) for use in
                perpetuity without any further consent or compensation. Content (comments, photos,
                rich media, etc.) should not bear the names, trademarks or logos of any third party.
              </p>
              <p>
                Sponsor assumes no responsibility for any claims against infringement with respect
                to any entry submitted. By entering this Contest each entrant (i) represents that
                the said entry does not infringe on any third party’s copyright materials,
                trade-mark or other intellectual property rights, (ii) agrees to release, indemnify,
                discharge and hold harmless the Sponsor, their respective parent, subsidiary and
                affiliated companies, and their respective officers, directors, employees, agents
                and representatives from any claim or liability arising from or related to
                submission of the entry and participation in this Contest, (iii) grants the Sponsor,
                an unlimited, royalty free, irrevocable, right and license to edit, modify
                reproduce, post and/or broadcast the entry in any form of media now known or
                hereinafter developed, including, without limitation, as contemplated within these
                Official Rules, and in any advertising or promotions created by the Sponsor in any
                form of media to promote this Contest and/or future contests similar to this
                Contest; and (iv) waives all moral rights in the entry in favour of the Sponsor.
              </p>
              <p>
                Sponsor further reserves the right, in its sole discretion, to edit any entry to
                blur out any trademarks or to remove any copyrighted content. Entries that do not
                conform to the specific requirements will be disqualified.
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p>&nbsp;</p>
            </td>
            <td width="99%">
              <p>&nbsp;</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>&nbsp;</p>
            </td>
            <td width="99%">
              <p>
                <strong>8. Additional Terms.</strong>
              </p>
            </td>
          </tr>
          <tr>
            <td>&nbsp;</td>
            <td width="99%">&nbsp;</td>
          </tr>
          <tr>
            <td>
              <p>&nbsp;</p>
            </td>
            <td width="99%">
              <p>
                In case of dispute as to the identity of any online entrant, entry will be declared
                made by the registered name and social media profile associated with such entry. Any
                potential winner may be requested to provide Sponsor with proof of identity. Any
                attempted form of entry not expressly permitted by these Official Rules is
                prohibited; no automatic, programmed, robotic or similar means of entry are
                permitted. Sponsor, its affiliates, partners and promotion and advertising agencies
                are not responsible for technical, hardware, software, telephone or other
                communications malfunctions, errors or failures of any kind, lost or unavailable
                network connections, web site, Internet, or ISP availability, unauthorized human
                intervention, traffic congestion, incomplete or inaccurate capture of entry
                information (regardless of cause) or failed, incomplete, garbled, jumbled or delayed
                computer transmissions which may limit one’s ability to enter the Contest, including
                any injury or damage to participant’s or any other person’s computer relating to or
                resulting from participating in this Contest or downloading any materials in this
                Contest.
              </p>
              <p>
                Subject in Québec to the jurisdiction of the Régie des alcools, des courses et des
                jeux, Sponsor reserves the right, in its sole discretion, to cancel, terminate,
                modify, extend or suspend this Contest for any reason including should any virus,
                bugs, non-authorized human intervention, fraud or other causes beyond its control
                corrupt or affect the administration, security, fairness or proper conduct of the
                Contest. In such case, Sponsor may select the potential winners from all eligible
                entries received prior to (and/or after if appropriate in the Sponsor’s sole
                discretion) the action taken by Sponsor. Sponsor reserves the right, in its sole
                discretion, to disqualify any individual it finds to be tampering with the entry
                process or the operation of the Contest or web site. Sponsor may prohibit an entrant
                from participating in the Contest or winning a prize if, in its sole discretion, it
                determines that said entrant is attempting to undermine the legitimate operation of
                the Contest by cheating, hacking, deception, or other unfair playing practices
                (including the use of automated quick entry programs) or intending to annoy, abuse,
                threaten or harass any other entrants or Sponsor representatives.
              </p>
              <p>
                <em>
                  CAUTION: ANY ATTEMPT BY AN ENTRANT TO DELIBERATELY DAMAGE ANY WEB SITE OR
                  UNDERMINE THE LEGITIMATE OPERATION OF THE CONTEST MAY BE A VIOLATION OF CRIMINAL
                  AND CIVIL LAWS AND SHOULD SUCH AN ATTEMPT BE MADE, THE SPONSOR RESERVES THE RIGHT
                  TO SEEK DAMAGES FROM ANY SUCH PERSON TO THE FULLEST EXTENT PERMITTED BY LAW.
                </em>
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p>&nbsp;</p>
            </td>
            <td width="99%">
              <p>&nbsp;</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>&nbsp;</p>
            </td>
            <td width="99%">
              <p>
                <strong>9. Personal Information.</strong>
              </p>
            </td>
          </tr>
          <tr>
            <td>&nbsp;</td>
            <td width="99%">&nbsp;</td>
          </tr>
          <tr>
            <td>
              <p>&nbsp;</p>
            </td>
            <td width="99%">
              <p>
                By participating in the Contest and voluntarily providing the personal information
                as set out in these Contest rules, you grant the Sponsor the right to use this
                personal information for the purposes of administering the Contest and publicity of
                this Contest, and to share this personal information with its agents and/or
                third-party service providers for these purposes. For more information about the
                Sponsor’s personal information handling practices, please see Sponsor’s privacy
                policy available at&nbsp;
                <a
                  href="https://www.walmartcanada.ca/privacy-notices"
                  target="_blank"
                  rel="noopener noreferrer">
                  https://www.walmartcanada.ca/privacy-notices
                </a>
                .
              </p>
              <p>
                Acceptance of a prize constitutes each winner’s permission for Sponsor to use
                his/her name, city and province of residence, likeness, photograph, picture,
                portrait, voice, any biographical information provided by each winner and/or any
                statements made by each winner regarding the Contest or Sponsor for advertising and
                promotional purposes, now and in the future, in any media without notice or
                additional compensation, except where prohibited by law.
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p>&nbsp;</p>
            </td>
            <td width="99%">
              <p>&nbsp;</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>&nbsp;</p>
            </td>
            <td width="99%">
              <p>
                <strong>10. List of Winners.</strong>
              </p>
            </td>
          </tr>
          <tr>
            <td>&nbsp;</td>
            <td width="99%">&nbsp;</td>
          </tr>
          <tr>
            <td>
              <p>&nbsp;</p>
            </td>
            <td width="99%">
              <p>
                A list of winners will be posted following each random drawing at&nbsp;[insert URL
                for list of Winners].
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p>&nbsp;</p>
            </td>
            <td width="99%">
              <p>&nbsp;</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>&nbsp;</p>
            </td>

            <td width="99%">
              <p>
                <strong>11. Sponsor and Contest Administrator.</strong>
              </p>
            </td>
          </tr>
          <tr>
            <td>&nbsp;</td>
            <td width="99%">&nbsp;</td>
          </tr>
          <tr>
            <td>
              <p>&nbsp;</p>
            </td>
            <td width="99%">
              <p>
                Sponsor is Walmart Canada Corp. Mississauga, Ontario L5N 1P9. Contest is
                administered by Mosaic (“
                <strong>Contest Administrator</strong>”).
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p>&nbsp;</p>
            </td>
            <td width="99%">
              <p>&nbsp;</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>&nbsp;</p>
            </td>
            <td width="99%">
              <p>
                <strong>12. Quebec Residents:</strong>
              </p>
            </td>
          </tr>
          <tr>
            <td>&nbsp;</td>
            <td width="99%">&nbsp;</td>
          </tr>
          <tr>
            <td>
              <p>&nbsp;</p>
            </td>
            <td width="99%">
              <p>
                Any litigation respecting the conduct or organization of a publicity contest may be
                submitted to the Régie des alcools, des courses et des jeux for a ruling. Any
                litigation respecting the awarding of a prize may be submitted to the Régie only for
                the purpose of helping the parties reach a settlement.
              </p>
            </td>
          </tr>
        </tbody>
      </table>
    </>
  );
}

export default Rules;
