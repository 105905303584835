import { FunctionComponent } from "react";
import Footer from "../../Components/FooterWinner";
import Header from "../../Components/Header";
import disableBrowserHistory from "../../Components/DisableBrowserBack";

interface ErrorProps {}

const Error: FunctionComponent<ErrorProps> = () => {
  disableBrowserHistory();
  return (
    <div className="wrapper wrapper--sticky-footer">
      <Header />
      <div className="main main--sticky-footer">
        <section className="section-prize">
          <div className="shell">
            <div className="section__inner">
              <h1>Something went wrong, please try again later.</h1>
            </div>
            {/* /.section__inner */}
          </div>
          {/* /.shell */}
        </section>
        {/* /.section-prize */}
      </div>
      {/* /.main */}

      <Footer />
    </div>
  );
};

export default Error;
