import { FunctionComponent } from "react";
import "./modal.css";

interface SignatureModalProps {
  setShowSignature: (value: boolean) => void;
  setSignImageUrl: (value: any) => void;
  signCanvas: any;
  saveSignature: (value: File) => void;
  children: JSX.Element | JSX.Element[];
}

const SignatureModal: FunctionComponent<SignatureModalProps> = ({
  setShowSignature,
  setSignImageUrl,
  signCanvas,
  saveSignature,
  children,
}) => {
  return (
    <div className="modal">
      <div className="popup js-popup " id="popup-1">
        <div className="popup__head">
          <h2>
            <b>Add your signature</b>
          </h2>

          <a className="js-close-popup popup__close" onClick={() => setShowSignature(false)}>
            <img src="assets/images/svg/close.svg" alt="close icon" />
          </a>
        </div>
        {/* /.popup__head */}

        <div className="popup__body">
          <p>Please add your digital signature in the space below </p>
          {children}
        </div>
        {/* /.popup__body */}

        <div className="popup__actions">
          <a
            href="#"
            className="btn btn-outline js-close-popup"
            onClick={() => setShowSignature(false)}>
            Cancel
          </a>

          <a
            className="btn btn-outline"
            onClick={() => {
              signCanvas.current.clear();
              setShowSignature(true);
              setSignImageUrl("");
            }}>
            Clear
          </a>

          <a
            className="btn btn-success popup__btn"
            onClick={async (e) => {
              e.preventDefault();

              saveSignature(signCanvas.current.getTrimmedCanvas().toDataURL("image/png"));
            }}>
            Confirm
          </a>
        </div>
      </div>
    </div>
  );
};

export default SignatureModal;
