import Modal from "react-modal";
import "./index.css";

export type LoadingProps = {
  /** Default: true */
  isOpen?: boolean;
  /** Default: My dialog */
  contentLabel?: string;
  /** Default: false */
  ariaHideApp?: boolean;
  /** Default: popup */
  className?: string;
  /** Default: modal */
  portalClassName?: string;
};

export default function Loading({
  isOpen,
  contentLabel,
  ariaHideApp,
  className,
  portalClassName,
}: LoadingProps) {
  isOpen ??= true;
  contentLabel ??= "My dialog";
  ariaHideApp ??= false;
  className ??= "loading";
  portalClassName ??= "modal";
  return (
    <Modal
      isOpen={isOpen}
      contentLabel={contentLabel}
      ariaHideApp={ariaHideApp}
      className={className}
      portalClassName={portalClassName}
      style={{
        overlay: {
          position: "fixed",
          zIndex: 1043,
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "rgba(0,0,0,0.5)",
        },
      }}>
      <div className="loading loading--full-height">
        <span className="loader"></span>
      </div>
    </Modal>
  );
}
