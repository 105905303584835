import { FunctionComponent } from "react";

interface ContestsProps {
  setModal: (value: boolean) => void;
}

const Contests: FunctionComponent<ContestsProps> = ({ setModal }) => {
  return (
    <>
      <div className="contest" style={{ overflowY: "scroll", height: "600px" }}>
        <p>
          I am submitting this Declaration with the understanding that it will be relied upon to
          determine my eligibility in the " Walmart Canada Holiday Contest 2023" (the
          &ldquo;Contest&rdquo;) sponsored by Walmart Canada Corp (the &ldquo;Sponsor&rdquo;).
        </p>
        <p>
          I affirm and represent that I am at least the age of majority in my territory of
          residence, have reviewed, understand and fully complied with (and will continue to fully
          comply with) the{" "}
          <a onClick={() => setModal(true)}>Official Contest Rules</a> (copy enclosed), and that I
          have committed no fraud or deception in entering the Contest or in claiming any prize. I
          further represent that I am not an employee of the Sponsor or of any other entity stated
          in the <a onClick={() => setModal(true)}>Official Contest Rules</a> whose employees are
          not eligible to enter the Contest, nor am I a member of the family of any such employee.
        </p>
        <p>
          I hereby give my consent to the Sponsor and their advertising/contest/sweepstakes agencies
          for the use of my name and/or likeness, without compensation, in any and all advertising
          and promotional materials relating to the Contest and similar promotions, which may be
          offered by the Sponsor.
        </p>
        <p>
          I understand and acknowledge and hereby, for myself, my heirs, executors and
          administrators, do waive and release any and all rights, claims and causes of action
          whatsoever I may have against Sponsor, as well as their advertising/promotion/sweepstakes
          agencies, and the officers, employees, directors, representatives, shareholders and agents
          of any of the foregoing entities, for any matter, cause or thing whatsoever arising out of
          my acceptance, possession and utilization of the prize that I have won in the Contest.
        </p>
        <p>
          I understand and acknowledge that I am solely responsible for obtaining and paying for any
          and all insurance relating to my acceptance and use of the prize, if insurance is
          applicable. I acknowledge and understand that neither the Sponsor nor any of their
          advertising/promotion/sweepstakes agencies have arranged for or carry any insurance for my
          benefit or the benefit of my heirs, executors and administrators relating to my acceptance
          and use of the prize.
        </p>
        <p>
          I further understand and acknowledge that the Sponsor and their
          advertising/contest/sweepstakes agencies are only responsible for the prize as described
          in the <a onClick={() => setModal(true)}>Official Contest Rules</a>; and, any and all
          other, unspecified costs relating to the acceptance and use of the prize are my sole
          responsibility.
        </p>
        <p>
          I understand and acknowledge that my right to the above prize is non-transferable and that
          Sponsor, as well as their advertising/contest/sweepstakes agencies make no warranties
          regarding the prize.
        </p>
        <p>
          <strong>The prize I have won is: </strong>One (1) Walmart gift card in
          the amount of CDN$100.
        </p>
        <p>
          It is understood and agreed that this is a complete RELEASE and DISCHARGE of all claims
          and rights of the undersigned against the aforementioned parties to be released, and that
          no action will be taken by or on behalf of the undersigned with respect to any such
          rights, it being understood that this release shall be binding upon the heirs, executors,
          and administrators of the undersigned.
        </p>
        <p>
          I agree to return immediately upon demand to the Sponsor (or a party acting on their
          behalf) any prize, or the value of said prize, which has been or may be awarded to me if
          any statement made by me in this Declaration is false.
        </p>
      </div>
    </>
  );
};

export default Contests;
