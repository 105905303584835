import FooterWinner from "../../../Components/FooterWinner";
import HeaderWinner from "../../../Components/Header";
import Rules from "../../../Components/Rules";

function ContestRules() {
  return (
    <div className="wrapper wrapper--sticky-footer">
      <HeaderWinner />
      <div className="main main--sticky-footer">
        <section className="section-form">
          <div className="shell">
            <div className="section__inner">
              {/* /.section__head */}

              <div className="section__body">
                <Rules/>
              </div>
              {/* /.section__body */}
            </div>
            {/* /.section__inner */}
          </div>
          {/* /.shell */}
        </section>
      </div>
      {/* /.main */}

      <FooterWinner />
    </div>
  );
}

export default ContestRules;
