import ReactDatePicker from "react-datepicker";
import { Control, Controller } from "react-hook-form";
import "react-datepicker/dist/react-datepicker.css";
import { RegisterForm } from "../../Pages/Open/Landing";
import dayjs from "dayjs";

type DatePickerProps = { control: Control<RegisterForm, any> };

function BirthdayDayPicker({ control }: DatePickerProps) {
  return (
    <Controller
      control={control}
      name="dob"
      render={({ field }) => (
        <ReactDatePicker
          className="field"
          onChange={(date: Date) => field.onChange(date)}
          selected={field.value || new Date()}
          showYearDropdown
          dateFormatCalendar="MMMM"
          yearDropdownItemNumber={150}
          scrollableYearDropdown
          minDate={new Date(dayjs(new Date()).subtract(500, "years").toISOString())}
          maxDate={new Date()}
        />
      )}
    />
  );
}

export default BirthdayDayPicker;
