import { FunctionComponent } from "react"

interface FooterWinnerProps {}

const FooterWinner: FunctionComponent<FooterWinnerProps> = () => {
  return (
    <footer className="footer">
      <div className="shell">
        <p>Copyright &copy; Walmart 2023</p>
      </div>
      {/* /.shell */}
    </footer>
  )
}

export default FooterWinner
