import { useState, useEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { campaignClient, setSessionClient } from "../../../api";
import FooterWinner from "../../../Components/FooterWinner";
import HeaderWinner from "../../../Components/Header";
import SignaturePad from "react-signature-canvas";
import {
  canadianPostalCodeRegex,
  looseNorthAmericanPhoneRegex,
  stringRegex,
} from "../../../helpers/regexes";
import Contests from "../../../Components/Contest/Contest";
import SignatureModal from "../../../Components/Signature";
import disableBrowserHistory from "../../../Components/DisableBrowserBack";
import Loading from "../../../Components/Loading";
import Rules from "../../../Components/Rules";
import Popup from "../../../Components/PopUp/PopUp";
import { b64toBlob } from "../../../helpers/utils";
import axios from "axios";

import dayjs from "dayjs";
import BirthdayDayPicker from "../../../Components/BirthdayDaypicker";

export type RegisterForm = {
  sessionKey: string;
  prizeWinnerKey: string;
  firstName: string;
  lastName: string;
  address: string;
  address2: string;
  province: string;
  city: string;
  postal: string;
  phone: string;
  dob: Date;
  answer: string;
  signatureUrl: string;
  stq: string;
};

export type PrizeClaimArgs = {
  error: string;
  claimed: boolean;
  message: string;
};

type CheckClaim = {
  error: string;
  message: string;
  result: boolean;
};

declare const window: Window &
  typeof globalThis & {
    vex: any;
  };

export default function PrizeClaim() {
  disableBrowserHistory();
  const navigate = useNavigate();
  const [submitting, setSubmitting] = useState(false);
  const [showSignature, setShowSignature] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [signImageUrl, setSignImageUrl] = useState<File>();

  const { sessionKey, prizeWinnerKey } = useParams();

  const signCanvas = useRef() as React.MutableRefObject<any>;

  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<RegisterForm>({
    mode: "onChange",
  });

  useEffect(() => {
    checkClaim();
  }, []);

  const getFileUploadUrl = async (sessionKey: string, type: string) => {
    try {
      let { uploadUrl, key } = await campaignClient.call<{
        uploadUrl: string;
        key: string;
      }>("getFileUploadUrlForUser", {
        sessionKey,
        filename: "signature",
        contentType: ".jpg",
      });
      return { uploadUrl, urlKey: key };
    } catch (e) {
      console.log(e);
    }
  };

  const saveSignatureToUpload = async (sign: any) => {
    const res = await getFileUploadUrl(sessionKey!, typeof sign);
    if (sign && res?.uploadUrl) {
      const block = sign.split(";");
      const contentType = block[0].split(":")[1];
      const realData = block[1].split(",")[1];
      const blob = b64toBlob(realData, contentType);
      try {
        const data = await blob.arrayBuffer();
        axios.put(res.uploadUrl, data, {
          onUploadProgress: function (progressEvent) {
            // const percentCompleted = Math.round(
            //   (progressEvent.loaded * 100) / progressEvent.total
            // );
          },
          headers: {
            "Content-Type": typeof blob,
          },
        });
      } catch (e) {
        console.log(e);
        window.vex.dialog.alert({
          unsafeMessage: "There is an issue while uploading a signature. Please try again",
        });
      }
      return res.urlKey;
    }
  };

  const checkClaim = async () => {
    if (submitting) {
      return;
    }
    setSubmitting(true);

    try {
      const { result, error, message } = await campaignClient.call<CheckClaim>("checkClaim", {
        prizeWinnerKey,
        sessionKey,
      });

      if (error === "EXPIRED") {
        window.vex.dialog.alert({
          unsafeMessage:
            "The link to claim your prize has already expired. Please check your original email for details.",
          callback: () => {
            navigate(`/error`);
          },
        });
        return;
      } else if (error === "PRIZE_CLAIMED") {
        window.vex.dialog.alert({
          unsafeMessage: "Prize is already claimed",
          callback: () => {
            navigate(`/congratulate`);
          },
        });
      } else if (error && message) {
        window.vex.dialog.alert({
          unsafeMessage: message,
          callback: () => {
            navigate(`/error`);
          },
        });
        return navigate("/error");
      } else if (result) {
        setSubmitting(false);

        return;
      } else {
        navigate("/error");
      }
    } catch (e) {
      navigate("/error");
    }
  };

  async function handleRegister(data: RegisterForm) {
    if (submitting) {
      return;
    }
    setSubmitting(true);

    if (signImageUrl) {
      const signatureUrl = await saveSignatureToUpload(signImageUrl);

      try {
        const { error, claimed }: PrizeClaimArgs = await campaignClient.call<PrizeClaimArgs>(
          "prizeClaim",
          {
            sessionKey,
            answer: data.stq,
            firstName: data.firstName.trim(),
            lastName: data.lastName.trim(),
            phone: data.phone,
            dob: dayjs(data.dob).format("YYYY-MM-DD"),
            address1: data.address,
            address2: data.address2,
            province: data.province,
            city: data.city,
            postal: data.postal,
            prizeWinnerKey,
            signatureUrl,
          }
        );

        if (error) {
          window.vex.dialog.alert({
            unsafeMessage: `Something went wrong.`,
            callback: () => {
              navigate("/error");
              return;
            },
          });
        }
        if (claimed) {
          setSessionClient(campaignClient.recoverSession(sessionKey));
          navigate("/congratulate");
        }
      } catch {
        window.vex.dialog.alert({
          unsafeMessage: "Something went wrong please try again.",
          callback: () => {
            navigate(`/error`);
          },
        });
      }
    }
    setSubmitting(false);
  }

  const renderProvincesDropdown = () => {
    const provinces: any = [
      "Alberta",
      "British Columbia",
      "Manitoba",
      "New Brunswick",
      "Newfoundland and Labrador",
      "Northwest Territories",
      "Nova Scotia",
      "Nunavut",
      "Ontario",
      "Prince Edward Island",
      "Quebec",
      "Saskatchewan",
      "Yukon",
    ];
    return provinces.map(function (province: any) {
      return (
        <option value={province} key={province}>
          {province}
        </option>
      );
    });
  };

  const saveSignature = async (sign: any) => {
    if (submitting) {
      return;
    }
    setSignImageUrl(sign);
    setShowSignature(false);
  };

  return (
    <>
      {submitting ? (
        <Loading />
      ) : (
        <div className="wrapper wrapper--sticky-footer">
          {showSignature && (
            <SignatureModal
              setShowSignature={setShowSignature}
              setSignImageUrl={setSignImageUrl}
              signCanvas={signCanvas}
              saveSignature={saveSignature}>
              <SignaturePad
                ref={signCanvas}
                penColor="black"
                canvasProps={{
                  className: "SigPad",
                  style: {
                    position: "relative",
                    display: "block",
                    zIndex: "9999",
                    width: "100%",
                    minHeight: "80px",
                    height: "150px",
                    background: "white",
                    border: "2px solid grey",

                    textDecoration: "none",
                  },
                  onClick: () => {
                    setSignImageUrl(signCanvas.current.getTrimmedCanvas().toDataURL("image/png"));
                  },
                  onChange: () => {
                    setSignImageUrl(signCanvas.current.getTrimmedCanvas().toDataURL("image/png"));
                  },
                  onTouchEnd: () => {
                    setSignImageUrl(signCanvas.current.getTrimmedCanvas().toDataURL("image/png"));
                  },
                }}
              />
            </SignatureModal>
          )}

          <Popup isOpen={showModal} setShowModal={setShowModal}>
            <Rules />
          </Popup>
          <HeaderWinner />
          <div className="main main--sticky-footer">
            <section className="section-form">
              <div className="shell">
                <div className="section__inner">
                  <header className="section__head">
                    <h1>Please complete the form below to claim your prize</h1>
                  </header>
                  {/* /.section__head */}

                  <div className="section__body">
                    <div className="form">
                      <form onSubmit={handleSubmit(handleRegister)}>
                        <div className="form__hint">
                          <p>* Indicates required field</p>
                        </div>
                        {/* /.form__hint */}

                        <div className="form__row">
                          <label htmlFor="first-name" className="form__label">
                            <b> First name *</b>
                          </label>

                          <div className="form__controls">
                            <input
                              className="field"
                              placeholder="First name"
                              {...register("firstName", {
                                required: {
                                  value: true,
                                  message: "Please enter your first name.",
                                },
                                pattern: {
                                  value: stringRegex,
                                  message: "Please enter a valid first name.",
                                },
                              })}
                            />
                          </div>
                          {errors.firstName && (
                            <p className="error-message">
                              <img src="/images/exclamation-mark.svg" title="Exclamation Mark" />
                              {errors.firstName.message}
                            </p>
                          )}
                        </div>

                        <div className="form__row">
                          <label htmlFor="last-name" className="form__label">
                            <b> Last name *</b>
                          </label>

                          <div className="form__controls">
                            <input
                              className="field"
                              placeholder="Last name"
                              {...register("lastName", {
                                required: {
                                  value: true,
                                  message: "Please enter your last name.",
                                },
                                pattern: {
                                  value: stringRegex,
                                  message: "Please enter a valid last name.",
                                },
                              })}
                            />
                          </div>

                          {errors.lastName && (
                            <p className="error-message">
                              <img src="/images/exclamation-mark.svg" title="Exclamation Mark" />
                              {errors.lastName.message}
                            </p>
                          )}
                        </div>

                        <div className="form__row">
                          <label htmlFor="address-1" className="form__label">
                            <b> Address line 1 *</b>
                          </label>

                          <div className="form__controls">
                            <input
                              className="field"
                              placeholder="Address line 1"
                              {...register("address", {
                                required: {
                                  value: true,
                                  message: "Please enter your address line 1.",
                                },
                              })}
                            />
                          </div>

                          {errors.address && (
                            <p className="error-message">
                              <img src="/images/exclamation-mark.svg" title="Exclamation Mark" />
                              {errors.address.message}
                            </p>
                          )}
                        </div>

                        <div className="form__row">
                          <label htmlFor="address-2" className="form__label">
                            <b> Address line 2</b>
                          </label>

                          <div className="form__controls">
                            <input
                              className="field"
                              placeholder="Address line 2"
                              {...register("address2")}
                            />
                          </div>
                        </div>

                        <div className="form__row">
                          <label htmlFor="territory" className="form__label">
                            <b> Province/territory *</b>
                          </label>

                          <div className="select">
                            <select
                              {...register("province", {
                                required: {
                                  value: true,
                                  message: "Select a province/territory.",
                                },
                              })}>
                              <option value="">Select</option>
                              {renderProvincesDropdown()}
                            </select>
                          </div>

                          {errors.province && (
                            <p className="error-message">
                              <img src="/images/exclamation-mark.svg" title="Exclamation Mark" />
                              {errors.province.message}
                            </p>
                          )}
                        </div>

                        <div className="form__row">
                          <label htmlFor="address-1" className="form__label">
                            <b> City *</b>
                          </label>

                          <div className="form__controls">
                            <input
                              className="field"
                              placeholder="City"
                              {...register("city", {
                                required: {
                                  value: true,
                                  message: "Please enter your city.",
                                },
                                pattern: {
                                  value: stringRegex,
                                  message: "Please enter a valid city.",
                                },
                              })}
                            />
                          </div>

                          {errors.city && (
                            <p className="error-message">
                              <img src="/images/exclamation-mark.svg" title="Exclamation Mark" />
                              {errors.city.message}
                            </p>
                          )}
                        </div>

                        <div className="form__row">
                          <label htmlFor="postal-code" className="form__label">
                            <b> Postal code *</b>
                          </label>

                          <div className="form__controls">
                            <input
                              className="field"
                              placeholder="Postal code"
                              {...register("postal", {
                                required: {
                                  value: true,
                                  message: "Please enter your postal code.",
                                },
                                pattern: {
                                  value: canadianPostalCodeRegex,
                                  message: "Please enter a valid postal code.",
                                },
                              })}
                            />
                          </div>

                          {errors.postal && (
                            <p className="error-message">
                              <img src="/images/exclamation-mark.svg" title="Exclamation Mark" />
                              {errors.postal.message}
                            </p>
                          )}
                        </div>
                        {/* /.form__row */}

                        <div className="form__row">
                          <label htmlFor="phone" className="form__label">
                            <b> Phone number*</b>
                          </label>

                          <div className="form__controls">
                            <input
                              className="field"
                              {...register("phone", {
                                required: {
                                  value: true,
                                  message: "Please enter your phone number.",
                                },
                                pattern: {
                                  value: looseNorthAmericanPhoneRegex,
                                  message: "Please enter a valid phone number.",
                                },
                              })}
                              placeholder="Phone number"
                            />
                          </div>

                          {errors.phone && (
                            <p className="error-message">
                              <img src="/images/exclamation-mark.svg" title="Exclamation Mark" />
                              {errors.phone.message}
                            </p>
                          )}
                        </div>
                        {/* /.form__row */}

                        <div className="form__row">
                          <label htmlFor="date" className="form__label">
                            <b> Date of birth *</b>
                          </label>
                          <div className="form__controls">
                            <BirthdayDayPicker control={control} />
                            {/* <Controller
                              control={control}
                              name="dob"
                              render={({ field }) => (
                                <DatePicker
                                  className="field"
                                  onChange={(date: Date) => field.onChange(date)}
                                  selected={new Date()}
                                  showYearDropdown
                                  dateFormatCalendar="MMMM"
                                  yearDropdownItemNumber={100}
                                  scrollableYearDropdown
                                  maxDate={new Date()}
                                />
                              )}
                            /> */}
                            {/* <input
                              placeholder="YYYY-MM-DD"
                              className="field"
                              type="date"
                              {...register("dob", {
                                required: {
                                  value: true,
                                  message: "Please enter your date of birth.",
                                },
                                pattern: {
                                  value: dateOfBirthRegex,
                                  message: "Please enter valid your date of birth.",
                                },
                              })}
                            /> */}
                          </div>

                          {errors.dob && (
                            <p className="error-message">
                              <img src="/images/exclamation-mark.svg" title="Exclamation Mark" />
                              {errors.dob.message}
                            </p>
                          )}
                        </div>
                        {/* /.form__row */}

                        <p>
                          If the section below is not completed by a Canada Resident Winner, he/she
                          will not be eligible to receive the prize. In order to receive your prize,
                          you must first correctly answer the Mathematical-Skill Testing Question
                          below (unaided by electronic or mechanical or otherwise):
                        </p>

                        <h3>Mathematical-Skill Testing Question:</h3>

                        <ul>
                          <li>Step 1: Multiply 9x14.</li>

                          <li>Step 2: Divide the answer from Step 1 by 6.</li>

                          <li>Step 3: Add 15 to the answer from Step 2.</li>

                          <li>Step 4: Subtract 34 from the answer from Step 3.</li>
                        </ul>

                        <div className="form__row">
                          <label htmlFor="question" className="form__label">
                            <b> Skill-testing question answer *</b>
                          </label>

                          <div className="form__controls">
                            <input
                              className="field"
                              {...register("stq", {
                                required: {
                                  value: true,
                                  message: "Please answer the skill-testing question.",
                                },
                                pattern: {
                                  value: /^[2]$/,
                                  message: "You must correctly answer the skill-testing question.",
                                },
                              })}
                            />
                          </div>

                          {errors.stq && (
                            <p className="error-message">
                              <img src="/images/exclamation-mark.svg" title="Exclamation Mark" />
                              {errors.stq.message}
                            </p>
                          )}
                        </div>
                        {/* /.form__row */}
                        <Contests setModal={setShowModal} />
                        <a
                          className="form__btn js-popup-btn btn btn-outline"
                          onClick={() => setShowSignature(true)}
                          style={{ marginTop: "20px" }}>
                          <span>Add my signature *</span>
                        </a>

                        {!signImageUrl ? (
                          <p
                            className="error-message"
                            style={{ marginTop: "0px", marginBottom: "30px" }}>
                            <img src="/images/exclamation-mark.svg" title="Exclamation Mark" />
                            Please add your signature.
                          </p>
                        ) : (
                          !showSignature && (
                            <div className="popup" id="popup-1">
                              <div className="popupClose">
                                <a
                                  onClick={() => {
                                    setSignImageUrl(undefined);
                                  }}
                                  className="js-close-popup popup__close">
                                  <img src="assets/images/svg/close.svg" alt="close icon" />
                                </a>
                              </div>
                              <div className="popup__body">
                                <div className="">
                                  <img
                                    src={String(signImageUrl)}
                                    alt="signature"
                                    width="100%"
                                    height="80px"
                                  />
                                </div>
                              </div>
                            </div>
                          )
                        )}
                        <div className="form__actions">
                          <button type="submit" className="btn-success form__btn">
                            Submit
                          </button>
                        </div>
                      </form>
                    </div>
                    {/* /.form */}
                  </div>
                  {/* /.section__body */}
                </div>
                {/* /.section__inner */}
              </div>
              {/* /.shell */}
            </section>
          </div>
          {/* /.main */}

          <a
            style={{ textAlign: "center" }}
            href="https://www.walmartcanada.ca/privacy-notice"
            target="_blank"
            rel="noopener noreferrer">
            Privacy policy
          </a>
          <FooterWinner />
        </div>
      )}
    </>
  );
}
